<template>
  <div class="bg-white p-2 p-lg-3">
    <b-row class="no-gutters">
      <b-col md="12" class="justify-content-start">
        <UploadFileV2
          textFloat="File"
          placeholder="Please Choose File"
          format="excel"
          name="file"
          text="*Please upload only file .xlsx less than 10 MB"
          isRequired
          v-on:onFileChange="onFileChange"
          v-on:delete="deleteFile"
          :fileName="filename"
          v-model="form.excel_file"
          :v="$v.form.excel_file"
          id="uploadfile"
        />
      </b-col>
      <b-form-checkbox
        class="mb-3"
        id="no-update"
        name="no-update"
        v-model="form.is_no_update"
        :value="1"
        :unchecked-value="0"
      >
        No Update Exist Customer
      </b-form-checkbox>
    </b-row>
    <b-row>
      <b-col>
        <router-link to="/report/customer">
          <b-button size="md" class="btn-cancel">Cancel</b-button>
        </router-link>
      </b-col>
      <b-col class="text-right">
        <b-button
          id="upload"
          class="main-color btn-save color-btn"
          size="md"
          :disabled="isDisable"
          @click="importData"
        >
          Upload
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UploadFileV2 from "@/components/inputs/UploadFileV2";
import { required } from "vuelidate/lib/validators";
export default {
  components: { UploadFileV2 },
  validations: {
    form: {
      excel_file: { required },
    },
  },
  data() {
    return {
      isDisable: true,
      form: {
        excel_file: "",
        email: "",
        is_no_update: 0,
      },
      filename: "",
      isLoading: false,
    };
  },
  methods: {
    onFileChange(file) {
      this.filename = file.name;
      this.isDisable = false;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.excel_file = reader.result.substring(
          str.length,
          reader.result.length
        );
      };
    },
    deleteFile(value) {
      this.form.excel_file = null;
      this.filename = null;
      this.isDisable = true;
    },
    importData(id) {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$emit("save", this.form);
    },
  },
};
</script>

<style></style>
