<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <h1 class="mr-sm-4 header-tablepage">Import Data</h1>
      </div>
      <b-tabs class="mt-3" v-model="tabIndex">
        <b-tab title="Customer">
          <FileView @save="(val) => onSave(val)" />
        </b-tab>
        <b-tab title="Billing">
          <FileView @save="(val) => onSave(val)" />
        </b-tab>
        <b-tab title="Shipping">
          <FileView @save="(val) => onSave(val)" />
        </b-tab>
        <b-tab title="Contact">
          <FileView @save="(val) => onSave(val)" />
        </b-tab>
      </b-tabs>
      <ModalInputEmail
        ref="ModalInputEmail"
        :form="form"
        @changeEmail="(val) => (form.email = val)"
        @submit="sendForm"
      />
    </div>
  </div>
</template>

<script>
import ModalInputEmail from "@/components/customer/ModalInputEmail";
import OtherLoading from "@/components/loading/OtherLoading";
import FileView from "./FileView.vue";
export default {
  components: {
    ModalInputEmail,
    OtherLoading,
    FileView,
  },

  data() {
    return {
      isDisable: true,
      form: {
        excel_file: "",
        email: "",
        is_no_update: 0,
      },
      tabIndex: 0,
      filename: "",
      isLoading: false,
    };
  },
  watch: {
    tabIndex() {
      this.form.excel_file = "";
      this.form.is_no_update = "";
      this.filename = "";
    },
  },
  methods: {
    onSave(val) {
      this.$refs.ModalInputEmail.show();
      this.form = val;
      this.form.email = this.$cookies.get("back_office_admin_email");
    },
    async sendForm() {
      this.isLoading = true;
      if (this.tabIndex == 0) {
        this.$store.commit("setImportFile", this.form);
        await this.$store.dispatch("fileCustomer", this.form);
        data = this.$store.state.importFile.respImport;
      } else {
      }
      if (this.tabIndex == 1) {
        var { data } = await this.axios.post(
          "/Import/importexcelcustomer/billing",
          this.form
        );
      } else if (this.tabIndex == 2) {
        var { data } = await this.axios.post(
          "/Import/importexcelcustomer/shipping",
          this.form
        );
      } else if (this.tabIndex == 3) {
        var { data } = await this.axios.post(
          "/Import/importexcelcustomer/contact",
          this.form
        );
      }
      if (data.result === 1) {
        this.isLoading = false;
        this.isDisable = true;
        this.$swal("Import Success!", {
          icon: "success",
        }).then(function () {
          window.location.href = "/report/customer";
        });
      } else {
        if (data.detail) {
          var page = "";
          switch (this.tabIndex) {
            case 1:
              page = "Billing";
              break;
            case 2:
              page = "Shipping";
              break;
            case 3:
              page = "Contact";
              break;

            default:
              page = "Customer";
              break;
          }
          this.$store.commit("setResponseFileError", data.detail);
          this.$router.push(`/customer/checkField?page=${page}`);
        } else {
          this.isLoading = false;
          this.$swal(data.message, {
            icon: "warning",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.color-btn {
  color: #fff;
}
</style>
